import {
  SET_USER,
  LATEST_REPORT_URL,
  SET_USER_DATA,
  SET_PREV_ASSESSMENT,
} from "../constants/Auth";

// Initial state for the user reducer
const initState = {
  loading: false,
  message: "",
  showMessage: false,
  report_url: "",
  isPrevAssessment: false,
  userData: {
    full_name: "speakho User",
    email: "",
    is_active: false,
    is_superuser: false,
    subscription_plan: {
      id: 0,
      plan_name: "",
      amount: 0,
      audio_duration: 0,
    },
  },
};

// User reducer function
const user = (state = initState, action) => {
  switch (action.type) {
    // Set user action
    case SET_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    // Set latest report URL action
    case LATEST_REPORT_URL:
      return {
        ...state,
        report_url: action.payload,
      };
    // Set user data action
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    // Set previous assessment action
    case SET_PREV_ASSESSMENT:
      return {
        ...state,
        isPrevAssessment: action.payload,
      };
    // Default case returns the current state
    default:
      return state;
  }
};

export default user;
