import axios from "axios";
import { API_BASE_URL } from "configs/AppConfig";
import store from "../redux/store";
import { setUser, setUserData, setPrevAssessment } from "../redux/actions/User";

// Retrieve the token from localStorage if it exists
let token = "";
if (window !== undefined && window.localStorage !== undefined) {
  token = window.localStorage.getItem("auth_token");
}

// Configuration for axios requests with authorization header
const config = {
  headers: {
    Authorization: "Bearer " + token,
  },
};

// Configuration for multipart/form-data requests
const multipartConfig = {
  headers: {
    "Content-Type": "multipart/form-data",
    // Authorization: "Bearer " + token,
  },
};

class AuthApi {
  // Function to handle user login
  login(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Create form data with username and password
        let formData = new FormData();
        formData.append("username", payload.username);
        formData.append("password", payload.password);

        // Send POST request to login endpoint
        const { data } = await axios.post(
          API_BASE_URL + "/auth/token",
          formData,
          multipartConfig
        );

        // Store the received token in localStorage
        localStorage.setItem("auth_token", data.access_token);
        resolve({ success: true });
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  // Function to handle user registration
  register(payload) {
    return new Promise(async (resolve, reject) => {
      try {
        // Send POST request to signup endpoint
        const { data } = await axios.post(
          API_BASE_URL + "/auth/signup",
          payload
        );

        // Resolve the promise if registration is successful
        if (data.success === "true") {
          resolve(data);
        }
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  // Function to handle user logout
  logout() {
    return new Promise(async (resolve, reject) => {
      try {
        // Remove the token from localStorage if it exists
        token.length > 0 && localStorage.removeItem("auth_token");
        resolve({ success: true });
      } catch (error) {
        reject(new Error(error));
      }
    });
  }

  // Function to check if the user has an assessment
  async checkAssessment(email) {
    try {
      // Send POST request to checkAssessment endpoint with email as a parameter
      const { data } = await axios.post(
        `${API_BASE_URL}/auth/checkAssessment`,
        null,
        {
          params: { email },
        }
      );
      return data;
    } catch (error) {
      console.error("checkAssessment error", error);
      return null;
    }
  }

  // Function to get the current user's data
  async getMe() {
    return new Promise(async (resolve, reject) => {
      try {
        // Retrieve the token from localStorage if it exists
        let token = "";
        if (window !== undefined && window.localStorage !== undefined) {
          token = window.localStorage.getItem("auth_token");
        }

        // Send POST request to get the current user's data
        const { data } = await axios.post(
          API_BASE_URL + "/user/me",
          {},
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );

        // Dispatch the user data to the Redux store
        store.dispatch(setUserData(data.user));

        // Check if the user has a previous assessment
        const assessmentData = await this.checkAssessment(data.user.email);
        if (assessmentData.message === "assessment found") {
          store.dispatch(setPrevAssessment(true));
        } else {
          store.dispatch(setPrevAssessment(false));
        }
        resolve(data);
      } catch (error) {
        if (error.response) {
          const { status } = error.response;
          if (status == 403) {
            window.location.href = "/auth/login";
          }
        }
        reject(new Error(error));
      }
    });
  }
}

// Create an instance of AuthApi and export it
const authApi = new AuthApi();
export default authApi;
