import {
  SET_USER,
  LATEST_REPORT_URL,
  SET_USER_DATA,
  SET_PREV_ASSESSMENT,
} from "../constants/Auth";

export const setUser = (data) => {
  return {
    type: SET_USER,
    payload: data,
  };
};

export const setReportURL = (data) => {
  return {
    type: LATEST_REPORT_URL,
    payload: data,
  };
};

export const setUserData = (data) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};

/**
 * Action creator for setting the previous assessment.
 */
export const setPrevAssessment = (value) => {
  return {
    type: SET_PREV_ASSESSMENT,
    payload: value,
  };
};
